.game_guessDiv {
  width: 20%;
  height: 35%;
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  transition: 0.5s;
}
.game_guessDiv:hover {
  width: 30%;
  height: 55%;
}
.game_guessBtn {
  width: 100%;
  height: 3rem;
  position: absolute;
  bottom: 0;
}
.game_guessBtn_disabled {
  cursor: not-allowed;
  background: rgb(217, 217, 217);
  background: linear-gradient(90deg, rgba(217, 217, 217, 1) 0%, rgba(163, 163, 163, 1) 100%) !important;
}
.game_roundSummary {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25rem;
  margin: 1rem 1rem;
  backdrop-filter: blur(8px) brightness(0.5) contrast(1.5);
  height: calc(70%);
  z-index: 3;
  bottom: 0;
  left: 0;
  border-radius: 0.5rem;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  box-sizing: border-box;
  padding: 1rem;
}
.game_roundSummary h1 {
  font-weight: 400;
  font-size: 30px;
}
.game_roundSummary h1 b {
  font-size: 40px;
  font-stretch: 10%;
  font-weight: 800;
  letter-spacing: -2px;
  background: linear-gradient(90deg, rgba(255, 240, 0, 1) 0%, rgba(27, 248, 79, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.game_roundSummary p {
  font-size: 20px;
}
.game_controls {
  z-index: 2;
  position: absolute;
  left: 0;
  margin: 0.9rem;
  top: 4rem;
}
.game_controls button {
  padding: 10px;
  display: flex;
}
.game_controls ion-icon {
  // color: white;
  font-size: 20px;
}
@media (orientation: portrait) {
  .game_guessDiv {
    width: 92%;
    height: 40%;
  }
  .game_guessDiv:hover {
    width: 92%;
    height: 40%;
  }
  .game_roundSummary {
    width: 92%;
    height: 60%;
  }
}
