.btn {
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  background: rgb(255, 240, 0);
  background-image: linear-gradient(90deg, rgba(255, 240, 0, 1) 0%, rgba(27, 248, 79, 1) 50%, rgba(255, 240, 0, 1) 100%);
  text-align: center;
  transition: all 0.4s ease-in-out;
  background-size: 200% 100%;
}
.btn:hover {
  background-position: 100% 100%;
  transition: all 0.4s ease-in-out;
}
@media (orientation: portrait) {
  h1 {
    font-size: 1.5rem !important;
  }
}
