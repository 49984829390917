@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&family=Roboto:wght@300;400;500&display=swap);
body{margin:0;font-family:"Nunito", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#17171c;color:white}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.nav{width:100%;height:10vh;position:absolute;top:0;display:flex;align-items:center;justify-content:space-between;background-color:#111116}.nav_logo img{height:10vh}.nav_leaveGame{width:10rem;height:3rem;margin:1rem}.nav_gameDiv{display:flex;align-items:center}.nav_gameDiv h1{font-weight:400;font-size:30px}.nav_gameDiv h1 b{font-size:40px;font-stretch:10%;font-weight:800;letter-spacing:-2px;background:linear-gradient(90deg, #fff000 0%, #1bf84f 100%);-webkit-background-clip:text;-webkit-text-fill-color:transparent}@media (orientation: portrait){.nav_logo img{height:5vh}.nav_leaveGame{width:5rem;height:4rem}}

.landing_container{display:flex;justify-content:center;align-items:center;width:100%;height:100%;flex-direction:column}.landing_container *{z-index:2}.landing_modes{display:flex;justify-content:space-evenly}.landing_mode{width:25rem;display:flex;justify-content:center}.landing_figure{width:100%;display:flex;flex-direction:column;align-items:center}.landing_figure button{width:90%;margin:-15%}.landing_figure img{width:100%;border-radius:0.5rem;aspect-ratio:4/3;object-fit:cover}@media (orientation: portrait){.landing_container h1{font-size:1.8rem !important}.landing_modes{flex-direction:column}.landing_mode{width:20rem;margin:1rem}.landing_figure button{width:80%}.landing_figure button h1{font-size:1.5rem !important}.landing_figure img{aspect-ratio:16/9}}

.game_guessDiv{width:20%;height:35%;z-index:2;position:absolute;bottom:0;right:0;margin:1rem;transition:0.5s}.game_guessDiv:hover{width:30%;height:55%}.game_guessBtn{width:100%;height:3rem;position:absolute;bottom:0}.game_guessBtn_disabled{cursor:not-allowed;background:#d9d9d9;background:linear-gradient(90deg, #d9d9d9 0%, #a3a3a3 100%) !important}.game_roundSummary{position:absolute;display:flex;flex-direction:column;justify-content:space-between;width:25rem;margin:1rem 1rem;-webkit-backdrop-filter:blur(8px) brightness(0.5) contrast(1.5);backdrop-filter:blur(8px) brightness(0.5) contrast(1.5);height:calc(70%);z-index:3;bottom:0;left:0;border-radius:0.5rem;box-shadow:0 8px 32px 0 rgba(31,38,135,0.37);box-sizing:border-box;padding:1rem}.game_roundSummary h1{font-weight:400;font-size:30px}.game_roundSummary h1 b{font-size:40px;font-stretch:10%;font-weight:800;letter-spacing:-2px;background:linear-gradient(90deg, #fff000 0%, #1bf84f 100%);-webkit-background-clip:text;-webkit-text-fill-color:transparent}.game_roundSummary p{font-size:20px}.game_controls{z-index:2;position:absolute;left:0;margin:0.9rem;top:4rem}.game_controls button{padding:10px;display:flex}.game_controls ion-icon{font-size:20px}@media (orientation: portrait){.game_guessDiv{width:92%;height:40%}.game_guessDiv:hover{width:92%;height:40%}.game_roundSummary{width:92%;height:60%}}

.btn{border:none;cursor:pointer;border-radius:0.5rem;background:#fff000;background-image:linear-gradient(90deg, #fff000 0%, #1bf84f 50%, #fff000 100%);text-align:center;transition:all 0.4s ease-in-out;background-size:200% 100%}.btn:hover{background-position:100% 100%;transition:all 0.4s ease-in-out}@media (orientation: portrait){h1{font-size:1.5rem !important}}

